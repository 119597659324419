/*
 * Created on Aug 22, 2017 3:33:51 PM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router, UrlTree} from '@angular/router';
import {Subject} from 'rxjs';

import {AmBreadcrumbModel} from '../theme/ambreadcrumb/ambreadcrumb.model';
import {AmMenuItemModel} from '../theme/ammenuitem/ammenuitem.model';
import {AmZoomModel} from '../theme/amzoom/amzoom.model';
import {AmZoomService} from '../theme/amzoom/amzoom.service';

import {PagesModel} from './pages.model';
import {WorkspaceService} from './workspace.service';

@Injectable()
export class PagesService {
    static readonly LOADER_IDLE: number = 0;
    static readonly LOADER_LOADING: number = 1;
    static readonly LOADER_ERROR: number = -1;

    static readonly MENU_FORNITO_ITEMS: AmMenuItemModel[] = [
        {
            id: 'mngestione', text: 'Gestione',
            children: [
                {id: 'ordifoday', icon: 'today', text: 'Ordini fornitori del giorno', href: '/pages/ordifoday/lst'},
                {id: 'ordifo', icon: 'chrome_reader_mode', text: 'Ordini fornitori', href: '/pages/ordifo'},
                {id: 'docuaq', icon: 'chrome_reader_mode', text: 'Documenti di carico', href: '/pages/docuaq'}
            ]
        },
        {
            id: 'aboutus', icon: 'info', text: 'Informazioni su', href: '/pages/aboutus'
        }
    ];

	static readonly MENU_ITEMS: AmMenuItemModel[] = [
        {
            id: 'mnarchivi', text: 'Archivi',
            children: [
                {
                    id: 'mnarchiviges', text: 'Archivi gestione',
                    children: [
                        {id: 'nazione', icon: 'flag', text: 'Nazioni', href: '/pages/nazione'},
                        {id: 'provin', icon: 'location_city', text: 'Province', href: '/pages/provin'},
                        {id: 'comune', icon: 'location_city', text: 'Comuni', href: '/pages/comune'},
                        {id: 'um', icon: 'straighten', text: 'Unità di misura', href: '/pages/um'},
                        {id: 'caumoma', icon: 'home', text: 'Causali movimento magazzino', href: '/pages/caumoma'},
                        {id: 'caumodoc', icon: 'edit', text: 'Causali modifica documenti', href: '/pages/caumodoc'},
                        {id: 'cauret', icon: 'autorenew', text: 'Causali rettifiche', href: '/pages/cauret'},
                        {id: 'caunotacre', icon: 'edit', text: 'Causali note di credito', href: '/pages/caunotacre'},
                        {id: 'ccotras', icon: 'transfer_within_a_station', text: 'Causali costi trasporto', href: '/pages/ccotras'},
                        {id: 'modapag', icon: 'local_atm', text: 'Modalità pagamento', href: '/pages/modapag'},
                        {id: 'valuta', icon: 'euro_symbol', text: 'Valute', href: '/pages/valuta'},
                        {id: 'banca', icon: 'account_balance', text: 'Banche', href: '/pages/banca'},
                        {id: 'aliqiva', icon: 'monetization_on', text: 'Aliquote IVA', href: '/pages/aliqiva'},
                        {id: 'tipcli', icon: 'people', text: 'Tipologie clienti', href: '/pages/tipcli'},
						{id: 'tipordo', icon: 'class', text: 'Tipologia ordine', href: '/pages/tipordo'},
                        {id: 'deltotins', icon: 'print', text: 'Archivio per Total Instructions', href: '/pages/deltotins'},
                        {id: 'stampa', icon: 'print', text: 'Archivio stampe', href: '/pages/stampa'},
                        {id: 'priorita', icon: 'priority_high', text: 'Priorità', href: '/pages/priorita'},
						{id: 'festivo', icon: 'favorite', text: 'Festività', href: '/pages/festivo'},
						{id: 'clafesta', icon: 'archive', text: 'Classi festività', href: '/pages/clafesta'}
                    ]
                },
                {
                    id: 'mnartico', text: 'Articoli',
                    children: [
                        {id: 'famiglia', icon: 'filter_vintage', text: 'Famiglie', href: '/pages/famiglia'},
                        {id: 'varian', icon: 'local_florist', text: 'Varietà', href: '/pages/varian'},
                        {id: 'confezi', icon: 'archive', text: 'Confezionamento', href: '/pages/confezi'},
                        {id: 'marchio', icon: 'change_history', text: 'Marchi', href: '/pages/marchio'},
                        {id: 'colore', icon: 'palette', text: 'Colori', href: '/pages/colore'},
                        {id: 'tipmisu', icon: 'straighten', text: 'Tipologie misure', href: '/pages/tipmisu'},
                        {id: 'categattr', icon: 'inbox', text: 'Categorie imballi', href: '/pages/categattr'},
                        {id: 'tipimba', icon: 'inbox', text: 'Attrezzature-Imballi', href: '/pages/tipimba'},
                        {id: 'cateweb', icon: 'archive', text: 'Categorie web', href: '/pages/cateweb'}
                    ]
                },
                {
                    id: 'mnanagrafica', text: 'Anagrafiche',
                    children: [
                        {id: 'cliente', icon: 'people', text: 'Clienti', href: '/pages/cliente'},
                        {id: 'fornito', icon: 'people', text: 'Fornitori', href: '/pages/fornito'},
                        {id: 'corriere', icon: 'directions_bike', text: 'Corrieri', href: '/pages/corriere'},
                        {id: 'agenzia', icon: 'people', text: 'Agenzie', href: '/pages/agenzia'},
                        {id: 'vendito', icon: 'people', text: 'Venditori', href: '/pages/vendito'},
                        {id: 'comaerea', icon: 'airplanemode_active', text: 'Compagnie aeree', href: '/pages/comaerea'},
                        {id: 'magazzino', icon: 'store', text: 'Magazzini', href: '/pages/magazzino'},
                        {id: 'societa', icon: 'business', text: 'Società', href: '/pages/societa'}
                    ]
                },
                {
                    id: 'mnsdi', text: 'Archivi SDI',
                    children: [
                        {id: 'caupagri', icon: 'archive', text: 'Causali pagamento ritenute', href: '/pages/caupagri'},
                        {id: 'naturiva', icon: 'archive', text: 'Nature IVA', href: '/pages/naturiva'},
                        {id: 'regfisc', icon: 'archive', text: 'Regimi fiscali', href: '/pages/regfisc'},
                        {id: 'sdicopa', icon: 'archive', text: 'Condizioni pagamento', href: '/pages/sdicopa'},
                        {id: 'sdimopa', icon: 'archive', text: 'Modalità pagamento', href: '/pages/sdimopa'},
                        {id: 'tipcassa', icon: 'archive', text: 'Tipologie casse previdenziali', href: '/pages/tipcassa'},
                        {id: 'tipprest', icon: 'archive', text: 'Tipologie cessioni prestazioni', href: '/pages/tipprest'},
                        {id: 'tipdocum', icon: 'archive', text: 'Tipologie documenti', href: '/pages/tipdocum'}
                    ]
                },
            ]
        },
        {
            id: 'mngestione', text: 'Gestione',
            children: [
                {
                    id: 'mndocuca', text: 'Documenti di carico',
                    children: [
                        {id: 'docuaq', icon: 'shopping_cart', text: 'Documenti di acquisto', href: '/pages/docuaq'},
                        {id: 'docuat', icon: 'inbox', text: 'Documenti di acquisto attrezzatura', href: '/pages/docuat'},
                        {id: 'documo', icon: 'swap_horiz', text: 'Documenti di movimento', href: '/pages/documo'},
                        {id: 'docuca', icon: 'chrome_reader_mode', text: 'Documenti fornitori', href: '/pages/docuca'}
                    ]
                },
                {
                    id: 'mndocuve', text: 'Documenti di vendita',
                    children: [
                        {id: 'ddt', icon: 'description', text: 'DDT', href: '/pages/ddt'},
                        {id: 'ddtreso', icon: 'local_shipping', text: 'DDT Resi Azienda', href: '/pages/ddtreso'},
                        {id: 'fatsem', icon: 'receipt', text: 'Fatture Semplici', href: '/pages/fatsem'},
                        {id: 'fatdir', icon: 'event_available', text: 'Fatture Differite', href: '/pages/fatdir'},
                        {id: 'notacre', icon: 'money_off', text: 'Note di Credito Resi', href: '/pages/notacre'},
                        {id: 'docugen', icon: 'shuffle', text: 'Documenti generici', href: '/pages/docugen'},
                        {id: 'docuve', icon: 'chrome_reader_mode', text: 'Documenti di vendita', href: '/pages/docuve'}
                    ]
                },
                {
                    id: 'mntrasporti', text: 'Trasporti, Trasformazioni e costi',
                    children: [
                        {id: 'temptrasp', icon: 'content_copy', text: 'Template trasporti', href: '/pages/temptrasp'},
                        {id: 'trasporto', icon: 'airplanemode_active', text: 'Trasporti', href: '/pages/trasporto'},
                        {id: 'trasforma', icon: 'transform', text: 'Trasformazioni', href: '/pages/trasforma'},
                        {
                            id: 'mnlistino', text: 'Listini',
                            children: [
                                {id: 'lisagtim', icon: 'chrome_reader_mode', text: 'Corrieri per agenzie', href: '/pages/lisagtim'},
                                {id: 'liscitim', icon: 'chrome_reader_mode', text: 'Corrieri per clienti', href: '/pages/liscitim'},
                                {id: 'lisfotim', icon: 'chrome_reader_mode', text: 'Corrieri per fornitori', href: '/pages/lisfotim'}
                            ]
                        },
                    ]
                },
                {
                    id: 'mnordini', text: 'Ordini a fornitori',
                    children: [
                        {id: 'ordifoday', icon: 'today', text: 'Ordini fornitori del giorno', href: '/pages/ordifoday/lst'},
                        {id: 'ordifo', icon: 'chrome_reader_mode', text: 'Ordini fornitori', href: '/pages/ordifo'},
                        {id: 'tempofo', icon: 'content_copy', text: 'Template ordini fornitori', href: '/pages/tempofo'}
                    ]
                },
                {
                    id: 'mnvendita', text: 'Ordini a clienti',
                    children: [
                        {id: 'ordicliday', icon: 'today', text: 'Ordini clienti del giorno', href: '/pages/ordicliday/lst'},
                        {id: 'ordicli', icon: 'people', text: 'Ordini clienti', href: '/pages/ordicli'},
                        {id: 'tempocli', icon: 'content_copy', text: 'Template ordini clienti', href: '/pages/tempocli'},
                        {
                            id: 'mnlistinoven', text: 'Listini',
                            children: [
                                {id: 'catcli', icon: 'people', text: 'Raggruppamenti listino per cliente', href: '/pages/catcli'},
                                {id: 'classigen', icon: 'local_florist', text: 'Classi', href: '/pages/classigen'},
                                {id: 'listino', icon: 'chrome_reader_mode', text: 'Listini per la vendita', href: '/pages/listino'},
                                {id: 'clivenlisim', icon: 'shopping_cart', text: 'Prezzi per Clienti', href: '/pages/clivenlisim'},
                                {id: 'venlisim', icon: 'shopping_cart', text: 'Prezzi per Listini', href: '/pages/venlisim'}
                            ]
                        },
                    ]
                },
                {
                    id: 'mamagazzini', text: 'Magazzini',
                    children: [
                        {id: 'mamerce', icon: 'store', text: 'Magazzino merci', href: '/pages/mamerce'},
                        {id: 'movime', icon: 'swap_horiz', text: 'Movimento merci', href: '/pages/movime'},
                        {id: 'magattre', icon: 'store', text: 'Magazzino attrezzature', href: '/pages/magattre'},
                        {id: 'movattre', icon: 'swap_horiz', text: 'Movimento attrezzature', href: '/pages/movattre'},
                    ]
                },
                {
                    id: 'maresret', text: 'Resi e Rettifiche',
                    children: [
                        {id: 'resattre', icon: 'assignment_return', text: 'Resi attrezzature', href: '/pages/resattre'},
                        {id: 'resime', icon: 'assignment_return', text: 'Resi merce', href: '/pages/resime'},
                        {id: 'rettifiche', icon: 'adjust', text: 'Rettifiche automatiche', href: '/pages/rettifiche'},
                        {id: 'retdirmer', icon: 'adjust', text: 'Rettifiche dirette di merce', href: '/pages/retdirmer'},
                        {id: 'retdiratt', icon: 'adjust', text: 'Rettifiche dirette di attrezzatura', href: '/pages/retdiratt'}
                    ]
                },
                {
                    id: 'stati', icon: 'apps', text: 'Statistiche', href: '/pages/stati'
                },
                {
                    id: 'partita', icon: 'search', text: 'Ricerca partita', href: '/pages/partita'
                }
            ]
        },
        {
			id: 'mntelefono', text: 'Telefono',
			children: [
                {id: 'contatto', icon: 'contact_phone', text: 'Contatti', href: '/pages/contatto'},
                {id: 'rubrica', icon: 'book', text: 'Numeri rubrica', href: '/pages/rubrica'}
			]
		},
		{
			id: 'mnprofili', text: 'Profili',
			children: [
				{id: 'utente', icon: 'person', text: 'Utenti', href: '/pages/utente'},
				{id: 'gruppo', icon: 'link', text: 'Gruppi', href: '/pages/gruppo'},
				{id: 'azione', icon: 'link', text: 'Azioni', href: '/pages/azione'}
			]
		},
        {
            id: 'aboutus', icon: 'info', text: 'Informazioni su', href: '/pages/aboutus'
        }
	];

	static readonly MENU_EMPTY: AmMenuItemModel[] = [
		{id: '', text: 'Valore cercato non trovato', icon: 'warning'}
    ];

	private static readonly PAGES_MODEL: PagesModel[] = [
        {
			id: 'aboutus', title: 'About Us', href: '/pages/aboutus',
			menu: ['aboutus'],
			matcher: PagesService.equalsMatcher,
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Informazioni su'}
			]
		},
        {
			id: 'agenzia', title: 'Agenzie',
			href: '/pages/agenzia/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'agenzia'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Agenzie'}
			]
		},
        {
			id: 'agenzia', title: PagesService.getDetTitle,
			href: '/pages/agenzia/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'agenzia'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Agenzie', href: '/pages/agenzia/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'aliqiva', title: 'Aiquote IVA',
			href: '/pages/aliqiva/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'aliqiva'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Aliquote IVA'}
			]
		},
		{
			id: 'aliqiva', title: PagesService.getDetTitle,
			href: '/pages/aliqiva/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'aliqiva'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Aliquote IVA', href: '/pages/aliqiva/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'azione', title: 'Azioni',
			href: '/pages/azione/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnprofili', 'azione'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Azioni'}
			]
		},
		{
			id: 'azione', title: PagesService.getDetTitle,
			href: '/pages/azione/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnprofili', 'azione'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Azioni', href: '/pages/azione/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'banca', title: 'Banche',
			href: '/pages/banca/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'banca'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Banche'}
			]
		},
        {
			id: 'banca', title: PagesService.getDetTitle,
			href: '/pages/banca/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'banca'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Banche', href: '/pages/banca/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'categattr', title: 'Raggruppamenti listino per cliente',
			href: '/pages/categattr/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipmisu'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Categorie imballi'}
			]
		},
		{
			id: 'categattr', title: PagesService.getDetTitle,
			href: '/pages/categattr/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipmisu'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Categorie imballi', href: '/pages/categattr/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'catcli', title: 'Raggruppamenti listino per cliente',
			href: '/pages/catcli/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'catcli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Raggruppamenti listino per cliente'}
			]
		},
		{
			id: 'catcli', title: PagesService.getDetTitle,
			href: '/pages/catcli/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'catcli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Raggruppamenti listino per cliente', href: '/pages/catcli/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'cateweb', title: 'Categorie web',
			href: '/pages/cateweb/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'cateweb'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Categorie web'}
			]
		},
        {
			id: 'cateweb', title: PagesService.getDetTitle,
			href: '/pages/cateweb/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'cateweb'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Categorie web', href: '/pages/cateweb/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'caumoma', title: 'Causali movimento magazzino',
			href: '/pages/caumoma/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caumoma'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali movimento magazzino'}
			]
		},
		{
			id: 'caumoma', title: PagesService.getDetTitle,
			href: '/pages/caumoma/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caumoma'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali movimento magazzino', href: '/pages/caumoma/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'caumodoc', title: 'Causali modifica documenti',
			href: '/pages/caumodoc/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caumodoc'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali modifica documenti'}
			]
		},
		{
			id: 'caumodoc', title: PagesService.getDetTitle,
			href: '/pages/caumodoc/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caumodoc'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali modifica documenti', href: '/pages/caumodoc/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'caupagri', title: 'Causali pagamento ritenute',
			href: '/pages/caupagri/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'caupagri'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali pagamento ritenute'}
			]
		},
		{
			id: 'caupagri', title: PagesService.getDetTitle,
			href: '/pages/caupagri/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'caupagri'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali pagamento ritenute', href: '/pages/caupagri/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'cauret', title: 'Causali rettifiche',
			href: '/pages/cauret/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'cauret'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali rettifiche'}
			]
		},
		{
			id: 'cauret', title: PagesService.getDetTitle,
			href: '/pages/cauret/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'cauret'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali rettifiche', href: '/pages/cauret/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'caunotacre', title: 'Causali note di credito',
			href: '/pages/caunotacre/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caunotacre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali note di credito'}
			]
		},
		{
			id: 'caunotacre', title: PagesService.getDetTitle,
			href: '/pages/caunotacre/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'caunotacre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali note di credito', href: '/pages/caunotacre/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'ccotras', title: 'Causali costi trasporto',
			href: '/pages/ccotras/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'ccotras'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali costi trasporto'}
			]
		},
		{
			id: 'ccotras', title: PagesService.getDetTitle,
			href: '/pages/ccotras/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'ccotras'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Causali costi trasporto', href: '/pages/ccotras/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'clafesta', title: 'Classi festività',
			href: '/pages/clafesta/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'clafesta'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Classi festività'}
			]
		},
        {
			id: 'clafesta', title: PagesService.getDetTitle,
			href: '/pages/clafesta/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'clafesta'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Classi festività', href: '/pages/clafesta/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'classigen', title: 'Classi',
			href: '/pages/classigen/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'classigen'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Classi'}
			]
		},
		{
			id: 'classigen', title: PagesService.getDetTitle,
			href: '/pages/classigen/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'classigen'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Classi', href: '/pages/classigen/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'cliente', title: 'Clienti',
			href: '/pages/cliente/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'cliente'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Clienti'}
			]
		},
		{
			id: 'cliente', title: PagesService.getDetTitle,
			href: '/pages/cliente/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'cliente'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Clienti', href: '/pages/cliente/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'clivenlisim', title: 'Prezzi per Clienti',
			href: '/pages/clivenlisim',
			matcher: PagesService.equalsWithRefMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'clivenlisim'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Prezzi per Clienti'}
			]
		},
        {
			id: 'colore', title: 'Colori',
			href: '/pages/colore/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'colore'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Colori'}
			]
		},
		{
			id: 'colore', title: PagesService.getDetTitle,
			href: '/pages/colore/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'colore'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Colori', href: '/pages/colore/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'comaerea', title: 'Compagnie aeree',
			href: '/pages/comaerea/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'comaerea'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Compagnie aeree'}
			]
		},
		{
			id: 'comaerea', title: PagesService.getDetTitle,
			href: '/pages/comaerea/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'comaerea'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Compagnie aeree', href: '/pages/comaerea/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'comune', title: 'Comuni',
			href: '/pages/comune/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'comune'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Comuni'}
			]
		},
		{
			id: 'comune', title: PagesService.getDetTitle,
			href: '/pages/comune/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'comune'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Comuni', href: '/pages/comune/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'confezi', title: 'Confezionamento',
			href: '/pages/confezi/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'confezi'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Confezionamento'}
			]
		},
		{
			id: 'confezi', title: PagesService.getDetTitle,
			href: '/pages/confezi/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'confezi'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Confezionamento', href: '/pages/confezi/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'contatto', title: 'Contatti',
			href: '/pages/contatto/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mntelefono', 'contatto'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Contatti', }
			]
		},
        {
			id: 'contatto', title: PagesService.getDetTitle,
			href: '/pages/contatto/det',
			matcher: PagesService.beginMatcher,
			menu: ['mntelefono', 'contatto'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Contatti', href: '/pages/contatto/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'corriere', title: 'Corrieri',
			href: '/pages/corriere/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'corriere'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Corrieri'}
			]
		},
		{
			id: 'corriere', title: PagesService.getDetTitle,
			href: '/pages/corriere/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'corriere'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Corrieri', href: '/pages/corriere/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'docuve', title: 'Documenti di vendita',
			href: '/pages/docuve/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'docuve'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di vendita'}
			]
		},
        {
			id: 'docuve', title: 'Documenti di vendita',
			href: '/pages/docuve/lstnotacre',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'notacre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di vendita'}
			]
		},
        {
			id: 'docuve', title: 'Creazione Nota di Credito',
			href: '/pages/docuve/lstcreatenotacre',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'docugen'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti generici', href: '/pages/docugen/lst'},
				{label: 'Creazione Nota di Credito'},
			]
		},
        {
			id: 'ddt', title: 'DDT',
			href: '/pages/ddt/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'ddt'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'DDT'}
			]
		},
        {
			id: 'ddt', title: PagesService.getDetTitle,
			href: '/pages/ddt/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'ddt'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'DDT', href: '/pages/ddt/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'ddtreso', title: 'DDT Resi Azienda',
			href: '/pages/ddtreso/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'ddtreso'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'DDT Resi Azienda'}
			]
		},
        {
			id: 'ddtreso', title: PagesService.getDetTitle,
			href: '/pages/ddtreso/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'ddtreso'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'DDT Resi Azienda', href: '/pages/ddtreso/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'deltotins', title: 'Archivio per Total Instructions',
			href: '/pages/deltotins/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'deltotins'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Archivio per Total Instructions'}
			]
		},
		{
			id: 'deltotins', title: PagesService.getDetTitle,
			href: '/pages/deltotins/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'deltotins'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Archivio per Total Instructions', href: '/pages/deltotins/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'docuaq', title: 'Documenti di acquisto',
			href: '/pages/docuaq/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'docuaq'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di acquisto'}
			]
		},
		{
			id: 'docuaq', title: PagesService.getDetTitle,
			href: '/pages/docuaq/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuca', 'docuaq'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di acquisto', href: '/pages/docuaq/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'docuaq', title: PagesService.getDetTitle,
			href: '/pages/docuaq/fornito/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuca', 'docuaq'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di acquisto', href: '/pages/docuaq/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'docuat', title: 'Documenti di acquisto attrezzatura',
			href: '/pages/docuat/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'docuat'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di acquisto attrezzatura'}
			]
		},
		{
			id: 'docuat', title: PagesService.getDetTitle,
			href: '/pages/docuat/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuca', 'docuat'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di acquisto attrezzatura', href: '/pages/docuat/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'docuca', title: 'Documenti fornitori',
			href: '/pages/docuca/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'docuca'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti fornitori'}
			]
		},
        {
			id: 'documo', title: 'Documenti di movimento',
			href: '/pages/documo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di movimento'}
			]
		},
		{
			id: 'documo', title: PagesService.getDetTitle,
			href: '/pages/documo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti di movimento', href: '/pages/documo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'docugen', title: 'Documenti generici',
			href: '/pages/docugen/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'docugen'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti generici'}
			]
		},
		{
			id: 'docugen', title: PagesService.getDetTitle,
			href: '/pages/docugen/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'docugen'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Documenti generici', href: '/pages/docugen/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'famiglia', title: 'Famiglie',
			href: '/pages/famiglia/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'famiglia'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Famiglie'}
			]
		},
		{
			id: 'famiglia', title: PagesService.getDetTitle,
			href: '/pages/famiglia/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'famiglia'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Famiglie', href: '/pages/famiglia/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'fatdir', title: 'Fatture Differite',
			href: '/pages/fatdir/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'fatdir'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fatture Differite'}
			]
		},
        {
			id: 'fatdir', title: PagesService.getDetTitle,
			href: '/pages/fatdir/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'fatdir'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fatture Differite', href: '/pages/fatdir/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'fatsem', title: 'Fatture Semplici',
			href: '/pages/fatsem/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'fatsem'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fatture Semplici'}
			]
		},
        {
			id: 'fatsem', title: PagesService.getDetTitle,
			href: '/pages/fatsem/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'fatsem'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fatture Semplici', href: '/pages/fatsem/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'festivo', title: 'Festività',
			href: '/pages/festivo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'festivo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Festività'}
			]
		},
        {
			id: 'festivo', title: PagesService.getDetTitle,
			href: '/pages/festivo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'festivo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Festività', href: '/pages/festivo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'fornito', title: 'Fornitori',
			href: '/pages/fornito/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'fornito'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fornitori'}
			]
		},
		{
			id: 'fornito', title: PagesService.getDetTitle,
			href: '/pages/fornito/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'fornito'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Fornitori', href: '/pages/fornito/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'gruppo', title: 'Gruppi',
			href: '/pages/gruppo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnprofili', 'gruppo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Gruppi'}
			]
		},
		{
			id: 'gruppo', title: PagesService.getDetTitle,
			href: '/pages/gruppo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnprofili', 'fornito'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Gruppi', href: '/pages/gruppo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'home', title: 'Home', href: '/pages/home',
			menu: undefined,
			matcher: PagesService.equalsMatcher,
			breadcrumb: [
				{label: 'Home'}
			]
		},
		{
			id: 'home', title: 'Home', href: '/pages/home',
			menu: undefined,
			matcher: PagesService.equalsMatcher,
			breadcrumb: [
				{label: 'Home'}
			]
		},
        {
			id: 'lisagtim', title: 'Listino corrieri agenzie',
			href: '/pages/lisagtim',
			matcher: PagesService.equalsWithRefMatcher,
			menu: ['mngestione', 'mntrasporti', 'mnlistino', 'lisagtim'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Listino corrieri agenzie'}
			]
		},
        {
			id: 'liscitim', title: 'Listino corrieri clienti',
			href: '/pages/liscitim',
			matcher: PagesService.equalsWithRefMatcher,
			menu: ['mngestione', 'mntrasporti', 'mnlistino', 'liscitim'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Listino corrieri clienti'}
			]
		},
        {
			id: 'lisfotim', title: 'Listino corrieri fornitori',
			href: '/pages/lisfotim',
			matcher: PagesService.equalsWithRefMatcher,
			menu: ['mngestione', 'mntrasporti', 'mnlistino', 'lisfotim'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Listino corrieri fornitori'}
			]
		},
        {
			id: 'listino', title: 'Listini per la vendita',
			href: '/pages/listino/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'listino'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Listini per la vendita'}
			]
		},
        {
			id: 'listino', title: PagesService.getDetTitle,
			href: '/pages/listino/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'listino'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Listini per la vendita', href: '/pages/listino/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'magattre', title: 'Magazzino attrezzature',
			href: '/pages/magattre/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mamagazzini', 'magattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Magazzino attrezzature'}
			]
		},
        {
			id: 'magattre', title: 'Attrezzature in magazzino',
			href: '/pages/magattre/lstddtreso',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'ddtreso'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature in magazzino'}
			]
		},
        {
			id: 'magattre', title: 'Attrezzature in magazzino',
			href: '/pages/magattre/lstdocumo',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature in magazzino'}
			]
		},
        {
			id: 'magattre', title: 'Attrezzature in magazzino',
			href: '/pages/magattre/lstnotacre',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'resattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature in magazzino'}
			]
		},
        {
			id: 'magattre', title: 'Attrezzature in magazzino',
			href: '/pages/magattre/lstresattre',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'resattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature in magazzino'}
			]
		},
        {
			id: 'magattre', title: 'Attrezzature in magazzino',
			href: '/pages/magattre/lstretdiratt',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'retdiratt'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature in magazzino'}
			]
		},
		{
			id: 'magazzino', title: 'Magazzini',
			href: '/pages/magazzino/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'magazzino'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Magazzini'}
			]
		},
        {
			id: 'magazzino', title: PagesService.getDetTitle,
			href: '/pages/magazzino/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'magazzino'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Magazzini', href: '/pages/magazzino/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'mamerce', title: 'Magazzino merci',
			href: '/pages/mamerce/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mamagazzini', 'mamarce'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Magazzino merci'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino',
			href: '/pages/mamerce/lstddtreso',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'ddtreso'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino',
			href: '/pages/mamerce/lstdocumo',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino per fornitore',
			href: '/pages/mamerce/lstdocumofor',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino per fornitore'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino per viaggio',
			href: '/pages/mamerce/lstdocumovia',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuca', 'documo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino per viaggio'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino',
			href: '/pages/mamerce/lstresime',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'resime'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino',
			href: '/pages/mamerce/lstretdirmer',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'retdirmer'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino'}
			]
		},
        {
			id: 'mamerce', title: 'Partite in magazzino',
			href: '/pages/mamerce/lsttrasforma',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mntrasporto', 'trasforma'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Partite in magazzino'}
			]
		},
		{
			id: 'marchio', title: 'Marchi',
			href: '/pages/marchio/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'marchio'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Marchi'}
			]
		},
        {
			id: 'marchio', title: PagesService.getDetTitle,
			href: '/pages/marchio/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'marchio'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Marchi', href: '/pages/marchi/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'modapag', title: 'Modalità pagamento',
			href: '/pages/modapag/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'modapag'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Modalità pagamento'}
			]
		},
        {
			id: 'modapag', title: PagesService.getDetTitle,
			href: '/pages/modapag/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'modapag'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Modalità pagamento', href: '/pages/modapag/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'movattre', title: 'Movimento attrezzature',
			href: '/pages/movattre/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mamagazzini', 'movattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Movimento attrezzature'}
			]
		},
        {
			id: 'movattre', title: PagesService.getDetTitle,
			href: '/pages/movattre/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mamagazzini', 'movattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Movimento attrezzature', href: '/pages/movattre/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'movime', title: 'Movimento merci',
			href: '/pages/movime/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mamagazzini', 'movime'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Movimento merci'}
			]
		},
        {
			id: 'movime', title: PagesService.getDetTitle,
			href: '/pages/movime/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mamagazzini', 'movime'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Movimento merci', href: '/pages/movime/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'nazione', title: 'Nazioni',
			href: '/pages/nazione/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'nazione'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Nazioni'}
			]
		},
        {
			id: 'nazione', title: PagesService.getDetTitle,
			href: '/pages/nazione/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'nazione'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Nazioni', href: '/pages/nazione/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'naturiva', title: 'Natura IVA',
			href: '/pages/naturiva/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'naturiva'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Natura IVA'}
			]
		},
        {
			id: 'naturiva', title: PagesService.getDetTitle,
			href: '/pages/naturiva/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'naturiva'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Natura IVA', href: '/pages/naturiva/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'notacre', title: 'Note di Credito Resi',
			href: '/pages/notacre/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mndocuve', 'notacre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Note di Credito Resi'}
			]
		},
        {
			id: 'notacre', title: PagesService.getDetTitle,
			href: '/pages/notacre/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mndocuve', 'notacre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Note di Credito Resi', href: '/pages/notacre/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'ordicli', title: 'Ordini clienti',
			href: '/pages/ordicli/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'ordicli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini clienti'}
			]
		},
        {
			id: 'ordicli', title: PagesService.getDetTitle,
			href: '/pages/ordicli/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'ordicli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini clienti', href: '/pages/ordicli/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'ordicli', title: 'Ordini clienti',
			href: '/pages/ordicli/lstsel',
            matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'ordicli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini clienti'}
			]
		},
        {
			id: 'ordicliday', title: 'Ordini fornitori del giorno',
			href: '/pages/ordicliday/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'ordicliday'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini clienti del giorno'}
			]
		},
		{
			id: 'ordicliday', title: PagesService.getDetTitle,
			href: '/pages/ordicliday/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'ordicliday'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini clienti del giorno', href: '/pages/ordicliday/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'ordifo', title: 'Ordini fornitori',
			href: '/pages/ordifo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnordini', 'ordifo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori'}
			]
		},
        {
			id: 'ordifo', title: PagesService.getDetTitle,
			href: '/pages/ordifo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'ordifo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori', href: '/pages/ordifo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'ordifo', title: PagesService.getDetTitle,
			href: '/pages/ordifo/fornito/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'ordifo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori', href: '/pages/ordifo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'ordifo', title: 'Ordini fornitori',
			href: '/pages/ordifo/lstsel',
            matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'ordifo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori'}
			]
		},
		{
			id: 'ordifoday', title: 'Ordini fornitori del giorno',
			href: '/pages/ordifoday/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnordini', 'ordifoday'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori del giorno'}
			]
		},
		{
			id: 'ordifoday', title: PagesService.getDetTitle,
			href: '/pages/ordifoday/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'ordifoday'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori del giorno', href: '/pages/ordifoday/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'ordifoday', title: PagesService.getDetTitle,
			href: '/pages/ordifoday/fornito/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'ordifoday'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ordini fornitori del giorno', href: '/pages/ordifoday/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'partita', title: 'Ricerca partita',
			href: '/pages/partita',
			matcher: PagesService.equalsMatcher,
			menu: [],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Ricerca partita'}
			]
		},
        {
			id: 'passwd', title: 'Cambio password',
			href: '/pages/passwd',
			matcher: PagesService.equalsMatcher,
			menu: [],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Cambio password'}
			]
		},
        {
			id: 'priorita', title: 'Priorità',
			href: '/pages/priorita/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'priorita'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Priorita'}
			]
		},
        {
			id: 'priorita', title: PagesService.getDetTitle,
			href: '/pages/priorita/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'priorita'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Priorità', href: '/pages/priorita/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'provin', title: 'Province',
			href: '/pages/provin/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'provin'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Province'}
			]
		},
        {
			id: 'provin', title: PagesService.getDetTitle,
			href: '/pages/provin/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'provin'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Province', href: '/pages/provin/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'regfisc', title: 'Regimi fiscali',
			href: '/pages/regfisc/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'regfisc'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Regimi fiscali'}
			]
		},
		{
			id: 'regfisc', title: PagesService.getDetTitle,
			href: '/pages/regfisc/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'regfisc'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Regimi fiscali', href: '/pages/regfisc/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'resattre', title: 'Resi attrezzature',
			href: '/pages/resattre/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'resattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Resi attrezzature'}
			]
		},
        {
			id: 'resattre', title: PagesService.getDetTitle,
			href: '/pages/resattre/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'maresret', 'resattre'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Resi attrezzature', href: '/pages/resattre/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'resime', title: 'Resi attrezzature',
			href: '/pages/resime/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'resime'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Resi merce'}
			]
		},
        {
			id: 'resime', title: PagesService.getDetTitle,
			href: '/pages/resime/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'maresret', 'resime'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Resi merce', href: '/pages/resime/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'rettifiche', title: 'Rettifiche automatiche',
			href: '/pages/rettifiche/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'rettifiche'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rettifiche automatiche'}
			]
		},
        {
			id: 'retdirmer', title: 'Rettifiche dirette di merce',
			href: '/pages/retdirmer/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'retdirmer'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rettifiche dirette di merce'}
			]
		},
        {
			id: 'retdirmer', title: PagesService.getDetTitle,
			href: '/pages/retdirmer/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'maresret', 'retdirmer'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rettifiche dirette di merce', href: '/pages/retdirmer/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'retdiratt', title: 'Rettifiche dirette di attrezzatura',
			href: '/pages/retdiratt/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'maresret', 'retdiratt'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rettifiche dirette di attrezzatura'}
			]
		},
        {
			id: 'retdiratt', title: PagesService.getDetTitle,
			href: '/pages/retdiratt/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'maresret', 'retdiratt'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rettifiche dirette di attrezzatura', href: '/pages/retdiratt/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'rubrica', title: 'Rubrica',
			href: '/pages/rubrica/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mntelefono', 'rubrica'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rubrica'}
			]
		},
		{
			id: 'rubrica', title: PagesService.getDetTitle,
			href: '/pages/rubrica/det',
			matcher: PagesService.beginMatcher,
			menu: ['mntelefono', 'rubrica'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Rubrica', href: '/pages/rubrica/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'sdicopa', title: 'Condizioni pagamento',
			href: '/pages/sdicopa/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'sdicopa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Condizioni pagamento'}
			]
		},
		{
			id: 'sdicopa', title: PagesService.getDetTitle,
			href: '/pages/sdicopa/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'sdicopa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Condizioni pagamento', href: '/pages/sdicopa/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'sdimopa', title: 'Modalità di pagamento',
			href: '/pages/sdimopa/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'sdimopa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Modalità di pagamento'}
			]
		},
        {
			id: 'sdimopa', title: PagesService.getDetTitle,
			href: '/pages/sdimopa/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'sdimopa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Modalità di pagamento', href: '/pages/sdimopa/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'societa', title: 'Società',
			href: '/pages/societa/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'societa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Società'}
			]
		},
        {
			id: 'societa', title: PagesService.getDetTitle,
			href: '/pages/societa/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'societa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Società', href: '/pages/societa/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'stampa', title: 'Archivio stampe',
			href: '/pages/stampa/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'stampa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Archivio stampe'}
			]
		},
		{
			id: 'stampa', title: PagesService.getDetTitle,
			href: '/pages/stampa/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'stampa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Archivio stampe', href: '/pages/stampa/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'stati', title: 'Statistiche', href: '/pages/stati',
			menu: ['stati'],
			matcher: PagesService.equalsMatcher,
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Statistiche'}
			]
		},
        {
			id: 'temptrasp', title: 'Template trasporti',
			href: '/pages/temptrasp/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mntrasporti', 'temptrasp'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template trasporti'}
			]
		},
        {
			id: 'temptrasp', title: PagesService.getDetTitle,
			href: '/pages/temptrasp/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mntrasporti', 'temptrasp'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template trasporti', href: '/pages/temptrasp/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tempocli', title: 'Template ordini clienti',
			href: '/pages/tempocli/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnvendita', 'tempocli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template ordini clienti'}
			]
		},
		{
			id: 'tempocli', title: PagesService.getDetTitle,
			href: '/pages/tempocli/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'tempocli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template ordini clienti', href: '/pages/tempocli/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tempocli', title: 'Template clienti',
			href: '/pages/tempocli/lstsel',
            matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnvendita', 'tempocli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template clienti'}
			]
		},
		{
			id: 'tempofo', title: 'Template ordini fornitori',
			href: '/pages/tempofo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mnordini', 'tempofo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template ordini fornitori'}
			]
		},
		{
			id: 'tempofo', title: PagesService.getDetTitle,
			href: '/pages/tempofo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'tempofo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template ordini fornitori', href: '/pages/tempofo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tempofo', title: 'Template ordini fornitori',
			href: '/pages/tempofo/lstsel',
            matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mnordini', 'tempofo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Template ordini fornitori'}
			]
		},
		{
			id: 'tipcassa', title: 'Tipologie casse',
			href: '/pages/tipcassa/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipcassa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie casse'}
			]
		},
		{
			id: 'tipcassa', title: PagesService.getDetTitle,
			href: '/pages/tipcassa/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipcassa'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie casse', href: '/pages/tipcassa/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'tipcli', title: 'Tipologia clienti',
			href: '/pages/tipcli/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'tipcli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologia clienti'}
			]
		},
		{
			id: 'tipcli', title: PagesService.getDetTitle,
			href: '/pages/tipcli/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'tipcli'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologia clienti', href: '/pages/tipcli/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tipdocum', title: 'Tipologie documenti',
			href: '/pages/tipdocum/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipdocum'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie documenti'}
			]
		},
		{
			id: 'tipdocum', title: PagesService.getDetTitle,
			href: '/pages/tipdocum/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipdocum'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie documenti', href: '/pages/tipdocum/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tipimba', title: 'Attrezzature-Imballi',
			href: '/pages/tipimba/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipimba'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature-Imballi'}
			]
		},
		{
			id: 'tipimba', title: PagesService.getDetTitle,
			href: '/pages/tipimba/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipimba'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Attrezzature-Imballi', href: '/pages/tipimba/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tipmisu', title: 'Tipologie misure',
			href: '/pages/tipmisu/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipmisu'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie misure'}
			]
		},
		{
			id: 'tipmisu', title: PagesService.getDetTitle,
			href: '/pages/tipmisu/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'tipmisu'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie misure', href: '/pages/tipmisu/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tipprest', title: 'Tipologie prestazioni',
			href: '/pages/tipprest/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipprest'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie prestazione'}
			]
		},
		{
			id: 'tipprest', title: PagesService.getDetTitle,
			href: '/pages/tipprest/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnsdi', 'tipprest'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie prestazione', href: '/pages/tipprest/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'tipordo', title: 'Tipologie prestazioni',
			href: '/pages/tipordo/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mngestio', 'tipordo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie ordini'}
			]
		},
		{
			id: 'tipordo', title: PagesService.getDetTitle,
			href: '/pages/tipordo/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mngestio', 'tipordo'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Tipologie ordini', href: '/pages/tipordo/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'trasforma', title: 'Trasformazioni',
			href: '/pages/trasforma/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mntrasporti', 'trasforma'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Trasformazioni'}
			]
		},
        {
			id: 'trasforma', title: PagesService.getDetTitle,
			href: '/pages/trasforma/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mntrasporti', 'trasforma'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Trasformazioni', href: '/pages/trasforma/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'trasporto', title: 'Trasporti',
			href: '/pages/trasporto/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mngestione', 'mntrasporti', 'trasporto'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Trasporti'}
			]
		},
        {
			id: 'trasporto', title: PagesService.getDetTitle,
			href: '/pages/trasporto/det',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mntrasporti', 'trasporto'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Trasporti', href: '/pages/trasporto/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'trasporto', title: PagesService.getDetTitle,
			href: '/pages/trasporto/riparti',
			matcher: PagesService.beginMatcher,
			menu: ['mngestione', 'mntrasporti', 'trasporto'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Trasporti', href: '/pages/trasporto/lst'},
                {label: 'Ripartizione'},
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'um', title: 'Unità di misura',
			href: '/pages/um/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'um'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Unità di misura'}
			]
		},
		{
			id: 'um', title: PagesService.getDetTitle,
			href: '/pages/um/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'um'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Unita di misura', href: '/pages/um/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'utente', title: 'Utenti',
			href: '/pages/utente/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnprofili', 'utente'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Utenti'}
			]
		},
		{
			id: 'utente', title: PagesService.getDetTitle,
			href: '/pages/utente/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnprofili', 'utente'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Utenti', href: '/pages/utente/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
		{
			id: 'valuta', title: 'Valute',
			href: '/pages/valuta/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'valuta'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Valute'}
			]
		},
		{
			id: 'valuta', title: PagesService.getDetTitle,
			href: '/pages/valuta/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnarchiviges', 'valuta'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Valute', href: '/pages/valuta/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
        },
		{
			id: 'varian', title: 'Varietà',
			href: '/pages/varian/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnartico', 'varian'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Varietà'}
			]
		},
		{
			id: 'varian', title: PagesService.getDetTitle,
			href: '/pages/varian/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnartico', 'varian'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Varietà', href: '/pages/varian/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'vendito', title: 'Venditori',
			href: '/pages/vendito/lst',
			matcher: PagesService.equalsMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'vendito'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Venditori'}
			]
		},
		{
			id: 'vendito', title: PagesService.getDetTitle,
			href: '/pages/vendito/det',
			matcher: PagesService.beginMatcher,
			menu: ['mnarchivi', 'mnanagrafica', 'vendito'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Venditori', href: '/pages/vendito/lst'}
			],
			breadcrumbPrepare: PagesService.getDetBreadcrumb
		},
        {
			id: 'venlisim', title: 'Prezzi per Listini',
			href: '/pages/venlisim',
			matcher: PagesService.equalsWithRefMatcher,
			menu: ['mngestione', 'mnvendita', 'mnlistinoven', 'venlisim'],
			breadcrumb: [
				{label: 'Home', href: '/pages/home'},
				{label: 'Prezzi per Listini'}
			]
		}
	];

	private static equalsMatcher(h1: string, h2: string): boolean {
        return h1 == h2;
    }

    private static equalsWithRefMatcher(h1: string, h2: string): boolean {

        return (
            (h1 == h2) ||
            (
                (h1.indexOf(h2) == 0) && (h1.endsWith("ref=1"))
            )
        );
    }

    private static beginMatcher(h1: string, h2: string): boolean {
        return h1.indexOf(h2) == 0;
    }

    private static getDetTitle(href: string): string {
        const pos: number = href.lastIndexOf('/det/');
        let title: string;

        if(pos == -1) {
            title = 'Dettaglio';
        } else {
            const after: string = href.substring(pos + 5);

            if(after.match("^([0\/]+)$")) {
                title = "Nuovo";
            } else {
                title = href.substring(pos + 5).replace(/\//g, '-');
            }
        }

        return title;
    }

    private static getDetBreadcrumb(href: string, breadcrumb: AmBreadcrumbModel[]): AmBreadcrumbModel[] {
        const ret: AmBreadcrumbModel[] = [];
        ret.push(...breadcrumb);
        ret.push({label: PagesService.getDetTitle(href)});
        return ret;
    }

    readonly onLoaderChanged: Subject<number> = new Subject();

	readonly onUrlChanged: Subject<PagesModel> = new Subject();

	readonly onSidenavChanged: Subject<string> = new Subject();

	constructor(
        private router: Router,
        private location: Location,
        private zoomService: AmZoomService,
        private workspaceService: WorkspaceService
	) {

        this.router.events.subscribe((event: any) => {

            if(event instanceof NavigationEnd) {
                const pagesModel: PagesModel = this.getPagesModel(event.urlAfterRedirects);

                if(this.onUrlChanged.observers.length > 0) {
                    this.onUrlChanged.next(pagesModel);
                }
            }
        });
	}

	getRoutePagesModel(): PagesModel {
        return this.getPagesModel(this.router.url);
	}

	getLocation(): string {
        return this.location.path(true);
	}

	setLocation(href: string): void {
        this.location.go(href);
        const pagesModel: PagesModel = this.getPagesModel(href);

        if(this.onUrlChanged.observers.length > 0) {
            this.onUrlChanged.next(pagesModel);
        }
	}

	getQueryParams(): any {
        const path: string = this.getLocation();
        const url: UrlTree = this.router.parseUrl(path);
        return url.queryParams;
	}

	addQueryParams(params: any): void {
        const path: string = this.getLocation();
        const url: UrlTree = this.router.parseUrl(path);

        Object.keys(params).forEach((key: string) => {
            url.queryParams[key] = params[key];
        });

        if(url.queryParams.zoom) {
            const zoom: AmZoomModel = this.zoomService.getZoomCurrent();

            if(zoom.routedst == path) {
                this.zoomService.updateZoomDst(zoom.idx, url.toString());
            }
        }

        this.location.go(url.toString());
	}

	delQueryParams(params: any[]): void {
        let path: string = this.getLocation();
        const pos: number = path.indexOf('?');

        if(pos > -1) {
            let url: UrlTree = this.router.parseUrl(path);
            const queryParams: any = {};
            path = path.substring(0, pos);

            Object.keys(url.queryParams).forEach((key: string) => {

                if(params.indexOf(key) == -1) {
                    queryParams[key] = url.queryParams[key];
                }
            });

            url = this.router.createUrlTree([path], {queryParams: queryParams});

            if(url.queryParams.zoom) {
                const zoom: AmZoomModel = this.zoomService.getZoomCurrent();

                if(zoom.routedst == path) {
                    this.zoomService.updateZoomDst(zoom.idx, url.toString());
                }
            }

            this.location.go(url.toString());
        }
	}

	private getPagesModel(href: string): PagesModel {

        if(!href) {
            href = this.router.url;
        }

        const parsedUrl: any = this.parseUrl(href);

        const page: PagesModel = PagesService.PAGES_MODEL.find((item: PagesModel) => {
            return item.matcher(parsedUrl.path, item.href);
        });

        if(page) {
            let breadcrumb: AmBreadcrumbModel[];
            let zoomLst: AmZoomModel[];

            if(page.breadcrumbPrepare) {
                breadcrumb = page.breadcrumbPrepare(parsedUrl.path, page.breadcrumb);
            }

            if(parsedUrl.zoom) {

                if(this.zoomService.getZoomValid(parsedUrl.zoom)) {
                    zoomLst = this.zoomService.getZoomLst(href);
                }

                if(!zoomLst) {
                    this.location.replaceState(parsedUrl.path, parsedUrl.query);
                    this.zoomService.clearZoom();
                }

            } else {
                const zoomFirst: AmZoomModel = this.zoomService.getZoomFirst();
                const zoomLast: AmZoomModel = this.zoomService.getZoomCurrent();

                if((!zoomLast) || (zoomLast.routesrc != href)) {
                    this.zoomService.clearZoom(((zoomFirst) && (zoomFirst.routesrc == href)));
                }
                /*
                if((parsedUrl.queryParams) && (parsedUrl.queryParams.ciref)) {
                    let redirectCondomi: string;

                    if(
                        (zoomFirst) && (zoomFirst.routesrc == href) &&
                        (zoomFirst.extra) && (zoomFirst.extra.condomiRedirectUrl)
                    ) {
                        redirectCondomi = zoomFirst.extra.condomiRedirectUrl;
                    } else {
                        redirectCondomi = this.workspaceService.getCondomiRedirectUrl();
                    }

                    if(redirectCondomi) {

                        if(breadcrumb) {
                            breadcrumb = this.setCondomiRef(redirectCondomi, breadcrumb);
                        } else {
                            breadcrumb = this.setCondomiRef(redirectCondomi, page.breadcrumb);
                        }
                    }
                }*/
    		}

            if(zoomLst) {
                const newpage: PagesModel = {...page};

                if(breadcrumb) {
                    newpage.breadcrumb = this.getZoomBreadcrumb(breadcrumb, zoomLst);
                } else {
                    newpage.breadcrumb = this.getZoomBreadcrumb(page.breadcrumb, zoomLst);
                }

                return newpage;
            } else if(breadcrumb) {
                const newpage: PagesModel = {...page};
                newpage.breadcrumb = breadcrumb;
                return newpage;
            } else {
                return page;
            }
        }
	}

	private parseUrl(href: string): any {
        const parsed: any = {};
        const urlTree: UrlTree = this.router.parseUrl(href);

        if(urlTree.queryParamMap.keys.length > 0) {
            const pos: number = href.lastIndexOf('?');

            parsed.zoom = urlTree.queryParams['zoom'];
            parsed.path = href.substring(0, pos);

            if(urlTree.queryParamMap.keys.length > 0) {
                parsed.queryParams = {};

                Object.keys(urlTree.queryParams).forEach((key: string) => {

                    if(key != 'zoom') {
                            parsed.queryParams[key] = urlTree.queryParamMap.get(key);
                    }
                });
            }

        } else {
            parsed.path = href;
        }

        return parsed;
	}

	private getZoomBreadcrumb(breadcrumb: AmBreadcrumbModel[], zoomLst: AmZoomModel[]): AmBreadcrumbModel[] {
        const ret: AmBreadcrumbModel[] = [];
        ret.push(breadcrumb[0]);

        for(var i = zoomLst.length - 1; i >= 0; i--) {
            const zoom: AmZoomModel = zoomLst[i];
            const parsedUrl: any = this.parseUrl(zoom.routesrc);
            let zbreadcrumb: AmBreadcrumbModel[];
            let offset: number;

            const page: PagesModel = PagesService.PAGES_MODEL.find((item: PagesModel) => {
                return item.matcher(parsedUrl.path, item.href);
            });

            if(page.breadcrumbPrepare) {
                zbreadcrumb = page.breadcrumbPrepare(parsedUrl.path, page.breadcrumb);
            } else {
                zbreadcrumb = page.breadcrumb;
            }

            if(
                (parsedUrl.queryParams) &&
                (parsedUrl.queryParams.ciref) &&
                (zoom.extra) &&
                (zoom.extra.condomiRedirectUrl)
            ) {
                zbreadcrumb = this.setCondomiRef(zoom.extra.condomiRedirectUrl, zbreadcrumb);
            }

            zbreadcrumb[zbreadcrumb.length - 1].href = parsedUrl.path;
            zbreadcrumb[zbreadcrumb.length - 1].queryParams = parsedUrl.queryParams;

            if(parsedUrl.zoom) {
                zbreadcrumb[zbreadcrumb.length - 1].queryParams = {zoom: parsedUrl.zoom};

                if(zoom.dsttype == 'lst') {
                    offset = 2;
                } else {
                    offset = 1;
                }

            } else {
                offset = 1;
            }

            for(var k = offset; k < zbreadcrumb.length; k++) {
                ret.push(zbreadcrumb[k]);
            }
        }

        ret.push(breadcrumb[breadcrumb.length - 1]);
        return ret;
	}

	private setCondomiRef(href: string, breadcrumb: AmBreadcrumbModel[]): AmBreadcrumbModel[] {
        const redirectUrl: any = this.parseUrl(href);

        const referer: PagesModel = PagesService.PAGES_MODEL.find((item: PagesModel) => {
            return item.matcher(redirectUrl.path, item.href);
        });

        if(referer) {
            let redirectBc: AmBreadcrumbModel[];

            if(referer.breadcrumbPrepare) {
                redirectBc = referer.breadcrumbPrepare(redirectUrl.path, referer.breadcrumb);
            } else {
                redirectBc = referer.breadcrumb;
            }

            return this.getCondomiBreadcrumb(href, redirectBc, breadcrumb);
        }

        return breadcrumb;
	}

	private getCondomiBreadcrumb(redirectHref: string, redirectBc: AmBreadcrumbModel[], appendBc: AmBreadcrumbModel[]): AmBreadcrumbModel[] {
        const ret: AmBreadcrumbModel[] = [];

        if(redirectBc.length > 0) {

            for(var idx = 0; idx < redirectBc.length; idx++) {

                if(idx == redirectBc.length - 1) {
                    redirectBc[idx].href = redirectHref;
                }

                ret.push(redirectBc[idx]);
            }
        }

        if(appendBc.length > 1) {

            for(var idx = 1; idx < appendBc.length; idx++) {

                if(idx < appendBc.length - 1) {
                    delete appendBc[idx].href;
                }

                ret.push(appendBc[idx]);
            }
        }

        return ret;
	}
}
