/*
 * Created on Aug 19, 2017 11:51:35 AM
 * Copyright Alfredo Marchini
 * http://www.alfredomarchini.it
 */
import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from '../guards/auth.guard';
import {PagesComponent} from './pages.component';

export const routes: Routes = [
    {path: 'login', loadChildren: () => import('./profilo/login/login.module').then(m => m.LoginModule)},
    {
        path: 'print',
        canActivateChild: [AuthGuard],
        children: [
            {path: '', redirectTo: '/pages/home', pathMatch: 'full'},
            {path: 'etiche', loadChildren: () => import('./docuca/etiche/etiche.module').then(m => m.EticheModule)},
            {path: 'etichemg', loadChildren: () => import('./magaz/etichemg/etichemg.module').then(m => m.EticheMgModule)},
            {path: 'etichecli', loadChildren: () => import('./anag/etichecli/etichecli.module').then(m => m.EtichecliModule)}
        ]
    },
	{
		path: 'pages',
		component: PagesComponent,
		canActivateChild: [AuthGuard],
		children: [
			{path: '', redirectTo: 'home', pathMatch: 'full'},
            {path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule)},
			{path: 'agenzia', loadChildren: () => import('./anag/agenzia/agenzia.module').then(m => m.AgenziaModule)},
			{path: 'aliqiva', loadChildren: () => import('./aliqiva/aliqiva.module').then(m => m.AliqivaModule)},
			{path: 'azione', loadChildren: () => import('./profilo/azione/azione.module').then(m => m.AzioneModule)},
			{path: 'banca', loadChildren: () => import('./conto/banca/banca.module').then(m => m.BancaModule)},
            {path: 'categattr', loadChildren: () => import('./artico/categattr/categattr.module').then(m => m.CategattrModule)},
			{path: 'caumoma', loadChildren: () => import('./magaz/caumoma/caumoma.module').then(m => m.CaumomaModule)},
			{path: 'caumodoc', loadChildren: () => import('./docuca/caumodoc/caumodoc.module').then(m => m.CaumodocModule)},
			{path: 'caupagri', loadChildren: () => import('./sdi/caupagri/caupagri.module').then(m => m.CaupagriModule)},
			{path: 'cauret', loadChildren: () => import('./rettifiche/cauret/cauret.module').then(m => m.CauretModule)},
			{path: 'caunotacre', loadChildren: () => import('./docuve/caunotacre/caunotacre.module').then(m => m.CaunotacreModule)},
            {path: 'cateweb', loadChildren: () => import('./artico/cateweb/cateweb.module').then(m => m.CatewebModule)},
			{path: 'catcli', loadChildren: () => import('./vendita/catcli/catcli.module').then(m => m.CatcliModule)},
			{path: 'ccotras', loadChildren: () => import('./magaz/ccotras/ccotras.module').then(m => m.CcotrasModule)},
			{path: 'clafesta', loadChildren: () => import('./artico/clafesta/clafesta.module').then(m => m.ClafestaModule)},
			{path: 'classigen', loadChildren: () => import('./vendita/classigen/classigen.module').then(m => m.ClassigenModule)},
			{path: 'cliente', loadChildren: () => import('./anag/cliente/cliente.module').then(m => m.ClienteModule)},
            {path: 'clivenlisim', loadChildren: () => import('./listino/clivenlisim/clivenlisim.module').then(m => m.ClivenlisimModule)},
			{path: 'colore', loadChildren: () => import('./artico/colore/colore.module').then(m => m.ColoreModule)},
			{path: 'comaerea', loadChildren: () => import('./anag/comaerea/comaerea.module').then(m => m.ComaereaModule)},
            {path: 'comune', loadChildren: () => import('./geo/comune/comune.module').then(m => m.ComuneModule)},
			{path: 'confezi', loadChildren: () => import('./artico/confezi/confezi.module').then(m => m.ConfeziModule)},
			{path: 'contatto', loadChildren: () => import('./anag/contatto/contatto.module').then(m => m.ContattoModule)},
			{path: 'corriere', loadChildren: () => import('./anag/corriere/corriere.module').then(m => m.CorriereModule)},
            {path: 'docuve', loadChildren: () => import('./docuve/docuve/docuve.module').then(m => m.DocuveModule)},
            {path: 'ddt', loadChildren: () => import('./docuve/ddt/ddt.module').then(m => m.DdtModule)},
            {path: 'ddtreso', loadChildren: () => import('./docuve/ddtreso/ddtreso.module').then(m => m.DdtresoModule)},
            {path: 'deltotins', loadChildren: () => import('./vendita/deltotins/deltotins.module').then(m => m.DeltotinsModule)},
            {path: 'docuaq', loadChildren: () => import('./docuca/docuaq/docuaq.module').then(m => m.DocuaqModule)},
            {path: 'docuat', loadChildren: () => import('./docuca/docuat/docuat.module').then(m => m.DocuatModule)},
            {path: 'docuca', loadChildren: () => import('./docuca/docuca/docuca.module').then(m => m.DocucaModule)},
            {path: 'documo', loadChildren: () => import('./docuca/documo/documo.module').then(m => m.DocumoModule)},
            {path: 'docugen', loadChildren: () => import('./docuve/docugen/docugen.module').then(m => m.DocugenModule)},
			{path: 'famiglia', loadChildren: () => import('./artico/famiglia/famiglia.module').then(m => m.FamigliaModule)},
            {path: 'fatdir', loadChildren: () => import('./docuve/fatdir/fatdir.module').then(m => m.FatdirModule)},
            {path: 'fatsem', loadChildren: () => import('./docuve/fatsem/fatsem.module').then(m => m.FatsemModule)},
            {path: 'festivo', loadChildren: () => import('./listino/festivo/festivo.module').then(m => m.FestivoModule)},
			{path: 'fornito', loadChildren: () => import('./anag/fornito/fornito.module').then(m => m.FornitoModule)},
			{path: 'gruppo', loadChildren: () => import('./profilo/gruppo/gruppo.module').then(m => m.GruppoModule)},
			{path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
            {path: 'listino', loadChildren: () => import('./vendita/listino/listino.module').then(m => m.ListinoModule)},
            {path: 'lisagtim', loadChildren: () => import('./listino/lisagtim/lisagtim.module').then(m => m.LisagtimModule)},
            {path: 'liscitim', loadChildren: () => import('./listino/liscitim/liscitim.module').then(m => m.LiscitimModule)},
            {path: 'lisfotim', loadChildren: () => import('./listino/lisfotim/lisfotim.module').then(m => m.LisfotimModule)},
			{path: 'magattre', loadChildren: () => import('./magaz/magattre/magattre.module').then(m => m.MagattreModule)},
			{path: 'magazzino', loadChildren: () => import('./magaz/magazzino/magazzino.module').then(m => m.MagazzinoModule)},
			{path: 'mamerce', loadChildren: () => import('./magaz/mamerce/mamerce.module').then(m => m.MamerceModule)},
			{path: 'marchio', loadChildren: () => import('./artico/marchio/marchio.module').then(m => m.MarchioModule)},
			{path: 'modapag', loadChildren: () => import('./modapag/modapag.module').then(m => m.ModapagModule)},
            {path: 'movattre', loadChildren: () => import('./magaz/movattre/movattre.module').then(m => m.MovattreModule)},
			{path: 'movime', loadChildren: () => import('./magaz/movime/movime.module').then(m => m.MovimeModule)},
			{path: 'naturiva', loadChildren: () => import('./sdi/naturiva/naturiva.module').then(m => m.NaturivaModule)},
			{path: 'nazione', loadChildren: () => import('./geo/nazione/nazione.module').then(m => m.NazioneModule)},
			{path: 'notacre', loadChildren: () => import('./docuve/notacre/notacre.module').then(m => m.NotacreModule)},
			{path: 'ordicli', loadChildren: () => import('./vendita/ordicli/ordicli.module').then(m => m.OrdicliModule)},
            {path: 'ordicliday', loadChildren: () => import('./vendita/ordicli/ordicliday.module').then(m => m.OrdicliDayModule)},
			{path: 'ordifo', loadChildren: () => import('./ordini/ordifo/ordifo.module').then(m => m.OrdifoModule)},
			{path: 'ordifoday', loadChildren: () => import('./ordini/ordifo/ordifoday.module').then(m => m.OrdifoDayModule)},
			{path: 'partita', loadChildren: () => import('./partita/partita.module').then(m => m.PartitaModule)},
			{path: 'passwd', loadChildren: () => import('./profilo/passwd/passwd.module').then(m => m.PasswdModule)},
            {path: 'priorita', loadChildren: () => import('./magaz/priorita/priorita.module').then(m => m.PrioritaModule)},
			{path: 'provin', loadChildren: () => import('./geo/provin/provin.module').then(m => m.ProvinModule)},
			{path: 'regfisc', loadChildren: () => import('./sdi/regfisc/regfisc.module').then(m => m.RegfiscModule)},
			{path: 'resattre', loadChildren: () => import('./magaz/resattre/resattre.module').then(m => m.ResattreModule)},
			{path: 'resime', loadChildren: () => import('./magaz/resime/resime.module').then(m => m.ResimeModule)},
			{path: 'rettifiche', loadChildren: () => import('./rettifiche/rettifiche/rettifiche.module').then(m => m.RettificheModule)},
			{path: 'retdirmer', loadChildren: () => import('./rettifiche/retdirmer/retdirmer.module').then(m => m.RetdirmerModule)},
			{path: 'retdiratt', loadChildren: () => import('./rettifiche/retdiratt/retdiratt.module').then(m => m.RetdirattModule)},
			{path: 'rubrica', loadChildren: () => import('./anag/rubrica/rubrica.module').then(m => m.RubricaModule)},
			{path: 'sdicopa', loadChildren: () => import('./sdi/sdicopa/sdicopa.module').then(m => m.SdicopaModule)},
			{path: 'sdimopa', loadChildren: () => import('./sdi/sdimopa/sdimopa.module').then(m => m.SdimopaModule)},
			{path: 'societa', loadChildren: () => import('./anag/societa/societa.module').then(m => m.SocietaModule)},
            {path: 'stampa', loadChildren: () => import('./stampa/stampa.module').then(m => m.StampaModule)},
            {path: 'stati', loadChildren: () => import('./stati/stati.module').then(m => m.StatiModule)},
			{path: 'tempocli', loadChildren: () => import('./vendita/tempocli/tempocli.module').then(m => m.TempocliModule)},
			{path: 'tempofo', loadChildren: () => import('./ordini/tempofo/tempofo.module').then(m => m.TempofoModule)},
			{path: 'temptrasp', loadChildren: () => import('./trasporti/temptrasp/temptrasp.module').then(m => m.TemptraspModule)},
			{path: 'tipcassa', loadChildren: () => import('./sdi/tipcassa/tipcassa.module').then(m => m.TipcassaModule)},
            {path: 'tipcli', loadChildren: () => import('./anag/tipcli/tipcli.module').then(m => m.TipCliModule)},
			{path: 'tipdocum', loadChildren: () => import('./sdi/tipdocum/tipdocum.module').then(m => m.TipdocumModule)},
            {path: 'tipimba', loadChildren: () => import('./magaz/tipimba/tipimba.module').then(m => m.TipimbaModule)},
            {path: 'tipmisu', loadChildren: () => import('./artico/tipmisu/tipmisu.module').then(m => m.TipmisuModule)},
			{path: 'tipprest', loadChildren: () => import('./sdi/tipprest/tipprest.module').then(m => m.TipprestModule)},
			{path: 'tipordo', loadChildren: () => import('./ordini/tipordo/tipordo.module').then(m => m.TipordoModule)},
			{path: 'trasforma', loadChildren: () => import('./trasforma/trasforma.module').then(m => m.TrasformaModule)},
			{path: 'trasporto', loadChildren: () => import('./trasporti/trasporto/trasporto.module').then(m => m.TrasportoModule)},
			{path: 'um', loadChildren: () => import('./um/um.module').then(m => m.UmModule)},
			{path: 'utente', loadChildren: () => import('./profilo/utente/utente.module').then(m => m.UtenteModule)},
			{path: 'valuta', loadChildren: () => import('./geo/valuta/valuta.module').then(m => m.ValutaModule)},
			{path: 'varian', loadChildren: () => import('./artico/varian/varian.module').then(m => m.VarianModule)},
			{path: 'vendito', loadChildren: () => import('./anag/vendito/vendito.module').then(m => m.VenditoModule)},
            {path: 'venlisim', loadChildren: () => import('./listino/venlisim/venlisim.module').then(m => m.VenlisimModule)}
		]
	}
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);