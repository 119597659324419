/* 
 *  Created on Jul 25, 2019
 *  Copyright Alfredo Marchini
 *  http://www.alfredomarchini.it
 */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AuthService} from '../auth/auth.service';
import {PagesService} from './pages.service';

@Injectable()
export class PagesInterceptor implements HttpInterceptor {
        
    constructor(
        private authService: AuthService,
        private service: PagesService,
        private router: Router
    ) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const count: number = this.service.onLoaderChanged.observers.length;        

        if(count > 0) {
            this.service.onLoaderChanged.next(PagesService.LOADER_LOADING);
        }
                
        return next.handle(req).pipe(tap(
            (event: HttpEvent<any>) => {
                
                if(event instanceof HttpResponse) {                    
                    const data: any = event.body;
                    
                    if(
                        (!data.result) &&
                        (data.errorMessages?.length == 1) &&
                        (data.errorMessages[0].ex == 'login.ex') 
                    ) {
                        
                        if(data.errorMessages[0].dex == 'passwd.expired') {                            
                            this.authService.setPasswdExpired(true);
                            this.router.navigateByUrl('/pages/passwd');
                        } else {
                            this.authService.redirectUrl = req.url;
                            this.authService.setSessionExpired();
                            this.router.navigateByUrl('/login');
                        }
                        
                    } else {
                        this.authService.resetLastRequest();
                    }
                    
                    if(count > 0) {
                        this.service.onLoaderChanged.next(PagesService.LOADER_IDLE);
                    }                    
                }
            },
            (error: any) => {

                if([401, 403].includes(error.status)) {
                    this.authService.setSessionExpired();
                    this.router.navigateByUrl('/login');
                } else {
                    this.service.onLoaderChanged.next(PagesService.LOADER_ERROR);
                }
            }
        ));
    }
}
